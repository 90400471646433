import React from "react";
import CaseStudyPreview from "./case-study-preview";

function CaseStudyPreviewGrid(props) {
    return (
        <section id="case-studies" className="py-12">
            <div className="container mx-auto">
                <h1 className="mb-10 uppercase text-3xl text-center px-5">{props.headerText}</h1>
                <ul className="grid grid-cols-1 p-5 space-y-10">
                    {props.nodes && props
                        .nodes
                        .map(node => (
                            <li key={node.id}>
                                <CaseStudyPreview {...node}/>
                            </li>
                        ))}
                </ul>
            </div>
        </section>
    );
}

CaseStudyPreviewGrid.defaultProps = {
    headerText: "",
    nodes: [],
};

export default CaseStudyPreviewGrid;
