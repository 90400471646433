import React from "react";
import {graphql} from "gatsby";
import CaseStudyPreviewGrid from "../components/case-study-preview-grid";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import {mapEdgesToNodes, filterOutDocsWithoutSlugs} from "../lib/helpers";
import PageParallax from "../components/page-parallax";

export const query = graphql `
query caseStudiesPageQuery {
  caseStudies: allSanityCaseStudies(
    sort: {fields: [publishedAt], order: DESC}
    filter: {slug: {current: {ne: null}}, publishedAt: {ne: null}}
  ) {
    edges {
      node {
        id
        mainImage {
          ...Image
          asset {
            gatsbyImageData(height: 500)
          }
        }
        title
        relatedClients {
          clients {
            clientName
          }
        }
        excerpt
        slug {
          current
        }
      }
    }
  }
  sanityCaseStudiesPage {
    caseStudiesHero {
      ...Image
    }
    headerText
  }
}
`;

const CaseStudiesPage = props => {
    const {data} = props;
    const projectNodes = data && data.caseStudies && mapEdgesToNodes(data.caseStudies).filter(filterOutDocsWithoutSlugs);
    return (
        <Layout>
            <SEO title="Case Studies" image={data.sanityCaseStudiesPage.caseStudiesHero}/>
            <PageParallax image={data.sanityCaseStudiesPage.caseStudiesHero} title="Case Studies"/>
            {projectNodes && projectNodes.length > 0 && <CaseStudyPreviewGrid nodes={projectNodes} headerText={data.sanityCaseStudiesPage.headerText}/>}
        </Layout>
    );
};

export default CaseStudiesPage;
