import React from "react";
import {Link} from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image";
import BlockContent from "./block-content";

function CaseStudyPreview(props) {

    const clientList = props
        .relatedClients
        .map((node, i) => (
            <li key={i} className="inline">{(i
                    ? ', '
                    : '') + node.clients.clientName}</li>
        ))

    return (
        <div
            className="grid grid-cols-1 lg:grid-cols-2 space-y-5 lg:space-y-0 lg:space-x-10">
            <Link to={`/case-studies/${props.slug.current}`} className="w-fit">
                <GatsbyImage
                    image={props.mainImage.asset.gatsbyImageData}
                    alt="Case Study Image"/>
            </Link>
            <div
                className="lg:border-l-2 h-full lg:pl-10 lg:flex lg:flex-col lg:justify-center">
                <div className="mb-3">
                    <div className="mb-3">
                        <h4 className="text-2xl">{props.title}</h4>
                        {props.relatedClients.length !== 0 && <div className="inline-block text-lg">
                            <p className="inline">Clients:&nbsp;</p>
                            <ul className="inline">{clientList}</ul>
                        </div>}
                    </div>
                    <div className="block-content">
                        <p>{props.excerpt}</p>
                    </div>
                </div>
                <Link to={`/case-studies/${props.slug.current}`} className="w-fit">
                    <button className="magnaBtn btn animate-pulse hover:animate-none">
                        <span>See Case Study</span>
                    </button>
                </Link>
            </div>
        </div>
    );
}

CaseStudyPreview.defaultProps = {
    relatedClients: []
};

export default CaseStudyPreview;
